<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
  iconColor: {
    type: String,
    default: 'bg-red-500',
  },
})

const swiper = ref(null)

const cardStyles = computed(() => {
  if ($voix.breakpoint.value === 'mobile' || $voix.breakpoint.value === 'tablet') {
    return {
      'max-width': '300px',
    }
  }
  else if (props.fields.size.value === 'small') {
    return {
      'max-width': '350px',
    }
  }
  return {}
})

const imageStyles = computed(() => {
  if ($voix.breakpoint.value === 'mobile' || $voix.breakpoint.value === 'tablet') {
    return {
      height: '250px',
    }
  }
  return { height: '350px' }
})

const multipleImages = computed(() => {
  let imageCount = 0

  if (props.fields.image.value?.[0]?.url)
    imageCount++

  if (props.fields.image2.value?.[0]?.url)
    imageCount++

  if (props.fields.image3.value?.[0]?.url)
    imageCount++

  return imageCount > 1
})
defineSlice({
  name: { label: 'Description Card', group: 'Glue', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
  preview: 'SlicesGlueGenericCardsDescriptionCard.jpg',
  description: 'Card with landscape image and description',
  tags: ['Card', 'Description'],
  fields: {
    size: { type: 'select', label: 'Size', allowNull: false, options: { full: 'Full', small: 'Small' } },
    image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 550, height: 319 } } },
    image2: { type: 'media', label: 'Image 2', breakpoints: { lg: { width: 550, height: 319 } } },
    image3: { type: 'media', label: 'Image 3', breakpoints: { lg: { width: 550, height: 319 } } },
    mobileImage: { type: 'media', label: 'Mobile Image', breakpoints: { sm: { width: 350, height: 350 } } },
    title: { type: 'text', label: 'Title' },
    borderOn: { type: 'checkbox', label: 'Title Border' },
    copy: { type: 'wysiwyg', label: 'Copy' },
    bookNowLink: { type: 'text', label: 'Book Now Link', enabled: false },
    link: { type: 'link', label: 'Link', enabled: false },
  },
  templates: [{
    label: 'Description Card',
    fields: {
      borderOn: { value: true },
      copy: { value: '<p><br>One of the first waterslides in Aquaventure and some may say the scariest, this 60-foot near-vertical drop from the top of The Mayan Temple into the shark-filled waters of The Mayan Temple Lagoon makes even the bravest scream out loud.<\/p>' },
      image: {
        value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis\/homepage\/heroes\/looking-forward\/leap-of-faith-mobile.jpg',
      },
      link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '\/new-page' } },
      size: { value: 'small' },
      title: { value: 'LEAP OF FAITH' },
    },
  }],
})

const { $voix } = useNuxtApp()
const { onLinkCLick } = useAtlantisLink()

function next() {
  swiper.value.slideNext()
}

function prev() {
  swiper.value.slidePrev()
}

function onSwiper(swiperInstance) {
  swiper.value = swiperInstance
}
</script>

<template>
  <div class="flex w-full h-full justify-center">
    <div class="flex justify-center" :class="{ 'swiper-no-swiping': !multipleImages }">
      <div class="flex justify-center md:max-w-lg lg:max-w-auto w-full" :style="cardStyles">
        <div class="bg-white body w-full flex flex-col">
          <div class="relative">
            <ClientOnly>
              <Swiper
                :slides-per-view="1"
                :loop="((fields.image2.value && fields.image3.value) && (fields.image2.value.length > 0 || fields.image3.value.length > 0))"
                :centered-slides="true"
                class="clipping-slider border-white"
                @swiper="onSwiper"
              >
                <SwiperSlide>
                  <div class="w-full h-[319px]">
                    <VoixMedia
                      v-if="
                        fields.image.value
                      "
                      class="w-full h-full object-cover" :field="fields.image"
                    />
                    <VoixMedia
                      v-else-if="fields.mobileImage.value"
                      class="w-full h-full object-cover" :field="fields.mobileImage"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide v-if="fields.image2.value?.length">
                  <div class="w-full h-[319px]">
                    <VoixMedia
                      class="w-full h-full object-cover" :field="fields.image2"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide v-if="fields.image3.value?.length">
                  <div class="w-full h-[319px]">
                    <VoixMedia
                      class="w-full h-full object-cover" :field="fields.image3"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </ClientOnly>
            <div
              v-if="multipleImages"
              class="absolute z-50 inset-0 pointer-events-none flex justify-between items-center px-8"
            >
              <button class="slider-button border-gray-500" @click="prev">
                <svg
                  class="w-7 h-7"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <button class="slider-button border-gray-500 next" @click="next">
                <svg
                  class="w-7 h-7 transform rotate-180"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            class="px-8 py-6 md:px-12 md:py-10 flex-1 flex flex-col justify-between items-start"
          >
            <div>
              <div
                class="font-sans text-2xl font-light leading-8 uppercase w-full tracking-wider"
                :class="{
                  'border-b border-gray-300 pb-3': fields.borderOn.value,
                }"
              >
                {{ fields.title.value }}
              </div>
              <div class="font-sans2 text-15 text-gray-500" :class="{ 'pt-6': fields.borderOn.value }" v-html="fields.copy.value" />
            </div>
            <div class="flex flex-col md:flex-row justify-between w-full">
              <BookingBookNow
                v-if="fields.bookNowLink.enabled"
                class="cursor-pointer glue-btn mt-6 border border-gray-700 text-gray-600 text-xs pt-2 pb-2 uppercase px-8 font-bold"
                location="footer"
              >
                <span class="uppercase font-bold font-sans">{{
                  fields.bookNowLink.value
                }}</span>
              </BookingBookNow>
              <a
                v-if="fields.link.enabled"
                :href="fields.link.value?.href"
                :target="fields.link.value.target"
                class="glue-btn mt-6 border border-gray-700 text-gray-600 text-xs pt-2 pb-2 uppercase px-8 font-bold"
                @click="onLinkCLick(fields.link.value?.href)"
              >
                {{ fields.link.value.text }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-wrapper {
  @apply max-w-[100vw];
}
.swiper-slide {
  @apply max-w-[100vw];
  width: auto;
  height: auto;
}
</style>
